export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('bw_state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

export const saveState = (state, rememberMe) => {
  try {
    const { user, token } = state;
    if (token) {
      const serializedState = JSON.stringify({
        user,
        token,
      });
      sessionStorage.setItem('bw_state', serializedState);
      if (rememberMe) {
        localStorage.setItem('username', user?.userName);
      } else {
        localStorage.removeItem('username');
      }
    }
  } catch (e) {
    // ignore errors
  }
};

export const emptyState = () => {
  sessionStorage.removeItem('bw_state');
  sessionStorage.removeItem('siteFilters');
};

export const getAccessToken = () => {
  const state = loadState();
  return state?.token || '';
};

export const currentTheme = localStorage.getItem('bw_theme') || 'light';
