import styled, { css } from 'styled-components';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { currentTheme } from '../../../configs/local-storage';

interface CustomRangePickerProps {
  mb?: number;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  cursor?: string;
  radius?: string;
}

type IMainRangePickerProps = CustomRangePickerProps & RangePickerProps;

const { RangePicker: rangePicker } = DatePicker;

const RangePicker = styled(rangePicker)<IMainRangePickerProps>`
  box-shadow: ${currentTheme === 'dark'
    ? '0px 4px 4px 0px #FFFFFF40 inset'
    : '0 4px 8px 0 #060c2933 inset'};
  border-radius: 2px;
  font-size: 14px;
  background: ${currentTheme === 'dark' ? '#FFFFFF33' : 'transparent'} !important;
  border: 0.2px solid #060c2940;
  & input {
    color: ${currentTheme === 'dark' ? '#fff' : ''} !important;
    &::placeholder {
      color: ${currentTheme === 'dark' ? '#d1d1d1' : ''} !important;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: ${currentTheme === 'dark' ? '#d1d1d1' : ''} !important;
    }
  }

  & .ant-picker-separator {
    color: ${currentTheme === 'dark' ? '#00d78f' : ''} !important;
  }
  & .ant-picker-clear {
    color: ${currentTheme === 'dark' ? '#00d78f' : ''} !important;
  }
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
`;

export default RangePicker;
