import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SelectProps, Select as select } from 'antd';
import { LoadingOutlined, MenuOutlined } from '@ant-design/icons';
import { colors } from 'constants/colors';
import { currentTheme } from '../../../configs/local-storage';

interface IMainSelectProps extends SelectProps {
  type?: string;
  addSearchField?: boolean;
  mode_type?: string;
  mb?: string;
  margin?: string;
  padding?: string;
  main_padding?: string;
  max_width?: string;
  width?: string;
  min_width?: string;
  height?: string;
  cursor?: string;
  fw?: string;
  back_color?: string;
  clear_right?: string;
  border_color?: string;
  icon_color?: string;
  shadow?: string;
  clear_opacity?: string;
}

interface selectProps extends IMainSelectProps {
  children?: any;
  customArrow?: any;
}

const Select = styled(select)<IMainSelectProps>`
  box-shadow: ${currentTheme === 'dark'
    ? '0px 4px 4px 0px #FFFFFF40 inset'
    : '0 4px 8px 0 #060c2933 inset'};
  .ant-select-selector {
    border-radius: 2px;
    font-size: 14px;
    padding: 0 8px !important;
    background: ${currentTheme === 'dark' ? '#FFFFFF33' : 'transparent'} !important;
    border: 0.2px solid #060c2940;

    .ant-select-selection-search {
      left: 8px;
    }
  }
  .ant-select-selection-item {
    padding-inline-end: 10px !important;
  }
  .anticon-menu {
    color: ${colors.main_color};
  }

  &.ant-select-multiple {
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-rest) {
        max-width: calc(100% - 20px);
      }
      &:has(.ant-select-selection-overflow-item-rest) {
        .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-rest) {
          max-width: calc(100% - 85px);
          margin-right: 5px;
        }
      }
    }
  }

  .ant-select-dropdown {
    background: ${currentTheme === 'dark' ? '#012c61' : '#fff'};

    .ant-select-item-option-content {
      color: ${currentTheme === 'dark' ? '#fff' : '#000'};
    }
  }
  .ant-select-selection-item {
    color: ${currentTheme === 'dark' ? '#fff' : '#000'};
  }
  .ant-select-selection-item-content {
    color: ${currentTheme === 'dark' ? '#fff' : '#000'};
  }
  .ant-select-arrow {
    color: ${currentTheme === 'dark' ? '#00D78F' : '#00000040'};
  }
  .ant-select-selection-placeholder {
    color: ${currentTheme === 'dark' ? '#fff' : '#00000040'};
  }
  ${props =>
    props.border_color &&
    css`
      .ant-select-selector {
        border: 1px solid ${props.border_color}!important;
      }
      .ant-select-arrow,
      .anticon-menu {
        color: ${props.border_color};
      }
    `}
  ${props =>
    props.icon_color &&
    css`
      .ant-select-arrow,
      .anticon-menu {
        color: ${props.icon_color};
      }
    `}
    ${props =>
    props.shadow &&
    css`
      .ant-select-selector {
        box-shadow: ${props.shadow} !important;
      }
    `}
  ${props =>
    props.mode_type === 'multiple' &&
    css`
      .ant-select-selector {
        padding: 0 5px;
      }
    `}
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      .ant-select-selector {
        padding: ${props.padding};
      }
    `}
  ${props =>
    props.main_padding &&
    css`
      padding: ${props.main_padding};
    `}
  ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width};
    `}
  ${props =>
    props.height &&
    css`
      .ant-select-selector {
        height: ${props.height};
      }
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.fw &&
    css`
      .ant-select-selection-item {
        font-weight: ${props.fw};
      }
    `}
    ${props =>
    props.clear_right &&
    css`
      .ant-select-clear {
        right: ${props.clear_right} !important;
      }
    `}
    ${props =>
    props.clear_opacity &&
    css`
      .ant-select-clear {
        opacity: ${props.clear_opacity} !important;
      }
    `}
  ${props =>
    props.back_color &&
    css`
      .ant-select-selector {
        background-color: ${props.back_color};
      }
    `}
`;

const SelectWithBurger = ({
  children,
  customArrow,
  onSearch,
  options,
  addSearchField = false,
  ...props
}: selectProps): React.JSX.Element => {
  const [addedOption, setAddedOption] = useState<string | null>(null);
  return (
    <Select
      suffixIcon={
        props?.loading ? (
          <LoadingOutlined spin={true} />
        ) : (
          customArrow || <MenuOutlined style={{ pointerEvents: 'none' }} />
        )
      }
      onSearch={e => {
        addSearchField && setAddedOption(e);
        onSearch?.(e);
      }}
      options={
        addSearchField && addedOption
          ? [...(options || []), { value: addedOption, label: addedOption }]
          : options || []
      }
      {...props}
    >
      {children}
    </Select>
  );
};

SelectWithBurger.Option = Select.Option;

export default SelectWithBurger;
