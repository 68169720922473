import { SwitchProps, Switch as toggle } from 'antd';
import styled, { css } from 'styled-components';

interface IMainSwitchProps extends SwitchProps {
  mb?: number;
  margin?: string;
  border?: string;
  radius?: string;
  padding?: string;
  width?: string;
  height?: string;
  back_color?: string;
  switched_color?: string;
  isheader?: boolean;
  switcher_color?: string;
}

const Switch = styled(toggle)<IMainSwitchProps>`
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.switched_color &&
    css`
      &.ant-switch-checked {
        background-color: ${props.switched_color} !important;
      }
    `}
    ${props =>
    props.switched_color &&
    css`
      &.ant-switch-unchecked {
        background-color: ${props.switched_color} !important;
      }
    `}
  ${props =>
    props.isheader &&
    css`
      .ant-switch-handle {
        width: 8px !important;
        height: 8px !important;
        margin: 0 0px 0 3px !important;
        &::before {
          background: blue;
        }
      }
    `}
    ${props =>
    props.switcher_color &&
    css`
      .ant-switch-handle {
        &::before {
          background: ${props.switcher_color};
        }
      }
    `}
`;

export default Switch;
