import styled, { css } from 'styled-components';
import { InputProps, Input as input } from 'antd';
import { currentTheme } from '../../../configs/local-storage';

interface IMainInputProps extends InputProps {
  type?: string;
  mb?: number;
  margin?: string;
  placeholder_color?: string;
  color?: string;
  padding?: string;
  input_padding?: string;
  height?: string;
  width?: string;
  cursor?: string;
  fz?: string;
  fw?: string;
  shadow?: string;
  radius?: string;
  border?: string;
}

const Input = styled(input)<IMainInputProps>`
  padding: 6.8px 11.8px;
  box-shadow: 0 4px 8px 0 #060c2933 inset !important;
  &.ant-input-disabled {
    color: ${currentTheme === 'dark' ? '#fff' : '#000'} !important;
  }
  ${props =>
    props.type === 'blue' &&
    css`
      border: 1px solid #000080;
    `}
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
    ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
    ${props =>
    props.placeholder_color &&
    css`
      .ant-input::placeholder {
        color: ${props.placeholder_color};
      }
    `}
    ${props =>
    props.color &&
    css`
      .ant-input {
        color: ${props.color};
      }
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.input_padding &&
    css`
      input {
        padding: ${props.input_padding};
      }
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
      &.ant-input-group-wrapper > .ant-input-wrapper {
        height: ${props.height};
        & input {
          height: ${props.height} !important;
        }
      }
    `}
    ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.fz &&
    css`
      font-size: ${props.fz}!important;
    `}
  ${props =>
    props.fw &&
    css`
      input {
        font-weight: ${props.fw};
      }
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
`;

export default Input;
