export const protectedRouts = {
  service: {
    mainDashboard: {
      pageTitle: 'MainDashboard',
      key: 'MainDashboard',
      pathname: '/',
      component: 'pages/mainDashboard/MainDashboard.page',
    },
    dashboard: {
      pageTitle: 'Dashboard',
      key: 'dashboard',
      pathname: '/dashboard',
      component: 'pages/service/dashboard.page',
    },
  },
  site: {
    mainDashboard: {
      pageTitle: 'MainDashboard',
      key: 'MainDashboard',
      pathname: '/',
      component: 'pages/mainDashboard/MainDashboard.page',
    },
    dashboard: {
      pageTitle: 'Dashboard',
      key: 'dashboard',
      pathname: '/dashboard',
      component: 'pages/site/dashboard.page',
    },
    lotInfo: {
      pathname: '/lot-info',
      key: 'lotInfo',
      component: 'pages/site/lot-info.page',
      pageTitle: 'Lot Info',
    },
    singleLotInfo: {
      pathname: '/lot-info/:lotId',
      key: 'singleLot',
      component: 'pages/site/lot-info.page',
      pageTitle: 'Lot Info',
    },
    completion: {
      pathname: '/completion',
      key: 'completion',
      component: 'pages/completion/dashboard.page',
      pageTitle: 'Completion',
    },
  },
  completion: {
    mainDashboard: {
      pageTitle: 'MainDashboard',
      key: 'MainDashboard',
      pathname: '/',
      component: 'pages/mainDashboard/MainDashboard.page',
    },
    dashboard: {
      pageTitle: 'Dashboard',
      key: 'dashboard',
      pathname: '/dashboard',
      component: 'pages/completion/dashboard.page',
    },
  },
};

const routes = {
  signIn: {
    pageTitle: 'Sign In',
    pathname: '/sign-in',
    key: 'signIn',
    component: 'pages/guest/sign-in.page',
  },
  notFound: {
    pathname: '/404',
    key: 'notFound',
    component: 'pages/not-found',
    pageTitle: 'Not Found',
  },
};

export const roleDefPaths = {
  site: '/',
  completion: '/',
  admin: '/',
};

export default routes;
