import styled, { css } from 'styled-components';
import { DatePicker as datepicker } from 'antd';
import { colors } from 'constants/colors';
import type { DatePickerProps } from 'antd';
import { currentTheme } from '../../../configs/local-storage';

interface CustomDatePickerProps {
  mb?: number;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  cursor?: string;
  radius?: string;
}

type IMainDatePickerProps = CustomDatePickerProps & DatePickerProps;

const DatePicker = styled(datepicker)<IMainDatePickerProps>`
  box-shadow: ${currentTheme === 'dark'
    ? '0px 4px 4px 0px #FFFFFF40 inset'
    : '0 4px 8px 0 #060c2933 inset'};
  border-radius: 2px;
  font-size: 14px;
  background: ${currentTheme === 'dark' ? '#FFFFFF33' : 'transparent'} !important;
  border: 0.2px solid #060c2940;
  & input {
    color: ${currentTheme === 'dark' ? '#fff' : ''} !important;
  }

  & .ant-picker-clear {
    color: ${currentTheme === 'dark' ? '#00d78f' : ''} !important;
  }

  & .ant-picker-suffix {
    color: ${colors.main_color};
  }
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
`;

export default DatePicker;
