import { Link, Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getUserRole } from '../app/services/selector-helpers';
import { useAppSelector } from '../app/store';
import { Col, Paragraph } from '../components/atoms';
import { roleDefPaths } from '../constants/routes';

const NotFoundWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const NotFoundPage = () => {
  const { pathname } = useLocation();
  const role = useAppSelector(getUserRole);

  const defPath = role ? roleDefPaths?.[role] : '/sign-in';

  if (pathname === '/') {
    return <Navigate to={defPath} />;
  }
  return (
    <NotFoundWrapper>
      <Col>
        <Paragraph fz={45} fw={600} mb={0}>
          404
        </Paragraph>
        <Paragraph fz={18} fw={600} mb={0}>
          Not Found
        </Paragraph>
        <Link to={defPath}>Go to main page</Link>
      </Col>
    </NotFoundWrapper>
  );
};

export default NotFoundPage;
