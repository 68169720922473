import { Col as col, ColProps } from 'antd';
import styled, { css } from 'styled-components';

interface MainColProps extends ColProps {
  mb?: number;
  margin?: string;
  position?: string;
  ml?: string;
  mr?: string;
  mt?: string;
  padding?: string;
  display?: string;
  height?: string;
  min_height?: string;
  width?: string;
  min_width?: string;
  overflow?: string;
  max_height?: string;
  bg_img?: string;
  align?: string;
  left?: string;
  right?: string;
  justify?: string;
  wrap?: string;
  pt?: string;
  pr?: string;
  pl?: string;
  direction?: string;
  border_radius?: string;
  border?: string;
  border_right?: string;
  border_bottom?: string;
  border_top?: string;
  cursor?: string;
  overflow_y?: string;
  shadow?: string;
  radius?: string;
  back_color?: string;
  background?: string;
  tab_span?: number;
  tab_display?: string;
  tab_pr?: string;
  md_padding?: string;
  hide_size?: number;
  show_size?: number;
  tab_width?: string;
  align_self?: string;
  border_image_source?: string;
  justify_self?: string;
  view?: 'popup';
}

const Col = styled(col)<MainColProps>`
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.position &&
    css`
      position: ${props.position};
    `}
    ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml} !important;
    `}
      ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr} !important;
    `}
      ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt} !important;
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${props =>
    props.min_height &&
    css`
      min-height: ${props.min_height};
    `}
    ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width};
    `}
  ${props =>
    props.max_height &&
    css`
      max-height: ${props.max_height};
    `}
   ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
    ${props =>
    props.bg_img &&
    css`
      background-image: url(${props.bg_img});
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
  ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
  ${props =>
    props.wrap &&
    css`
      flex-wrap: ${props.wrap};
    `}
  ${props =>
    props.align_self &&
    css`
      align-self: ${props.align_self};
    `}
    ${props =>
    props.justify_self &&
    css`
      justify-self: ${props.justify_self};
    `}
    ${props =>
    props.pt &&
    css`
      padding-top: ${props.pt};
    `}
    ${props =>
    props.pr &&
    css`
      padding-right: ${props.pr}!important;
    `}
    ${props =>
    props.pl &&
    css`
      padding-left: ${props.pl}!important;
    `}
    ${props =>
    props.left &&
    css`
      left: ${props.left}!important;
    `} 
    ${props =>
    props.right &&
    css`
      right: ${props.right}!important;
    `}
  ${props =>
    props.direction &&
    css`
      display: flex;
      flex-direction: ${props.direction};
    `}
    ${props =>
    props.border_radius &&
    css`
      border-radius: ${props.border_radius};
    `}
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex}!important;
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.border_right &&
    css`
      border-right: ${props.border_right};
    `}
    ${props =>
    props.border_bottom &&
    css`
      border-bottom: ${props.border_bottom};
    `}
  ${props =>
    props.border_top &&
    css`
      border-top: ${props.border_top};
    `}
  ${props =>
    props.border_image_source &&
    css`
      border-image-source: ${props.border_image_source};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.overflow_y &&
    css`
      overflow-y: ${props.overflow_y};
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}
  ${props =>
    props.tab_span &&
    css`
      @media screen and (max-width: 1300px) {
        flex: 0 0 ${(props.tab_span / 24) * 100}%;
        max-width: ${(props.tab_span / 24) * 100}%;
        .ant-typography,
        .anticon {
          font-size: 12px !important;
        }
      }
    `}
  ${props =>
    props.tab_display &&
    css`
      @media screen and (max-width: 1300px) {
        display: ${props.tab_display};
      }
    `}
  ${props =>
    props.tab_pr &&
    css`
      @media screen and (max-width: 1300px) {
        padding-right: ${props.tab_pr}!important;
      }
    `}
  ${props =>
    props.tab_width &&
    css`
      @media screen and (max-width: 1300px) {
        width: ${props.tab_width}!important;
      }
    `}
  ${props =>
    props.md_padding &&
    css`
      @media screen and (max-width: 768px) {
        padding: ${props.md_padding}!important;
      }
    `}
  ${props =>
    props.hide_size &&
    css`
      @media screen and (max-width: ${props.hide_size}px) {
        display: none;
      }
    `}
  ${props => {
    if (props.show_size) {
      return css`
        display: none;
      `;
    }
  }}
  ${props =>
    props.show_size &&
    css`
      display: none;
      @media screen and (max-width: ${props.show_size}px) {
        display: flex;
      }
    `}
`;

/** @component */
export default Col;
